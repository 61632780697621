import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll'; // Import react-scroll for smooth scrolling

const Navbar = () => {
  const [navbarBg, setNavbarBg] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle the mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Change navbar background on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarBg('bg-black');
      } else {
        setNavbarBg('');
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`fixed top-0 left-0 w-full z-20 transition-colors duration-300 ${navbarBg}`}>
      <div className="container mx-auto px-6 py-4 flex items-center justify-between">
        
        {/* Logo */}
        <div className="flex items-center space-x-3">
          <img src="/header.jpg" alt="Logo" className="h-10 w-10 object-contain" />
          <a className="text-white font-bold text-2xl" href="#home">
            Bike Rental Ranchi
          </a>
        </div>

        {/* Hamburger menu for mobile */}
        <button className="block md:hidden text-white" onClick={toggleMenu}>
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>

        {/* Links - visible on desktop, collapsible on mobile */}
        <div className={`md:flex items-center ${isMenuOpen ? 'block' : 'hidden'} md:block`}>
          <ul className="flex flex-col md:flex-row md:space-x-6">
            <li>
              <Link
                className="text-white font-semibold cursor-pointer"
                to="home"
                smooth={true}
                duration={500}
                onClick={toggleMenu}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className="text-white font-semibold cursor-pointer"
                to="services"
                smooth={true}
                duration={500}
                onClick={toggleMenu}
              >
                Offerings
              </Link>
            </li>
            <li>
              <Link
                className="text-white font-semibold cursor-pointer"
                to="footer"
                smooth={true}
                duration={500}
                onClick={toggleMenu}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
